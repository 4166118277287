<template>
  <div>
    <div class="lg:p-12 max-w-xl lg:my-0 my-12 mx-auto p-6 space-y-">
      <form class="lg:p-10 p-6 space-y-3 relative bg-white shadow-xl rounded-md" v-on:submit.prevent="changePassword">
        <h1 class="lg:text-2xl text-xl font-semibold mb-6">Đổi mật khẩu mới</h1>
        <div class="bg-red-500 border p-4 relative rounded-md uk-alert" v-if="message">
          <p class="text-white">{{ message }}</p>
        </div>
        <div>
          <label class="mb-0">Nhập mật khẩu mới của bạn</label>
          <input v-model="password" type="password" placeholder="******" class="bg-gray-100 h-12 mt-2 px-3 rounded-md w-full">
        </div>
        <div>
          <button type="submit" class="bg-blue-600 font-semibold p-2 mt-5 rounded-md text-center text-white w-full">
            Đổi mật khẩu</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";

export default {
  name: 'ChangePassword',
  data() {
    return {
      password: "",
      message: "",
      disabled: false
    }
  },
  methods: {
    changePassword() {
      this.disabled = true;

      let query = `mutation($password: String!) {
        changePassword(password: $password)
      }`;

      ApiService.graphql(query, {password: this.password})
          .then(({data}) => {
            if (data.data && data.data.changePassword) {
              this.$router.push({name: "Login", query: {ref: 'changePassword'}});
            } else {
              this.disabled = false;
              this.message = data.errors[0].message;
            }
          })
          .catch((response) => {
            this.disabled = false;
            this.message = response.message;
          });
    }
  }
}
</script>
